import { template as template_72529299834d434b82e2b482dea92fcc } from "@ember/template-compiler";
import { Input } from "@ember/component";
import concatClass from "discourse/helpers/concat-class";
import i18n from "discourse-common/helpers/i18n";
const PreferenceCheckbox = template_72529299834d434b82e2b482dea92fcc(`
  <div class={{concatClass "controls" @class}} ...attributes>
    <label class="checkbox-label">
      <Input @type="checkbox" @checked={{@checked}} disabled={{@disabled}} />

      {{#if @labelCount}}
        {{i18n @labelKey count=@labelCount}}
      {{else}}
        {{i18n @labelKey}}
      {{/if}}
    </label>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PreferenceCheckbox;
