import { template as template_0ae8fa38df3943d395000f6b378d5789 } from "@ember/template-compiler";
const WelcomeHeader = template_0ae8fa38df3943d395000f6b378d5789(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
