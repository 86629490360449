import { template as template_554a25ee12b946889e2c974d6deef712 } from "@ember/template-compiler";
const FKControlMenuContainer = template_554a25ee12b946889e2c974d6deef712(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
