import { template as template_4eb102f61e47478c9012818efdee4277 } from "@ember/template-compiler";
const FKText = template_4eb102f61e47478c9012818efdee4277(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
