import { template as template_4622e3dec527430b8719830c554af318 } from "@ember/template-compiler";
const FKLabel = template_4622e3dec527430b8719830c554af318(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
