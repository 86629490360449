import { template as template_3ede37f2dc4947b58251d152ab0a67be } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
const PrivateMessageGlyph = template_3ede37f2dc4947b58251d152ab0a67be(`
  {{#if @shouldShow}}
    {{#if @href}}
      <a href={{@href}} title={{i18n @title}} aria-label={{i18n @ariaLabel}}>
        <span class="private-message-glyph-wrapper">
          {{icon "envelope" class="private-message-glyph"}}
        </span>
      </a>
    {{~else}}
      <span class="private-message-glyph-wrapper">
        {{icon "envelope" class="private-message-glyph"}}
      </span>
    {{~/if}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PrivateMessageGlyph;
